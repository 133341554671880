<template>
    <TresCanvas alpha shadows :key="level">
        <!--<TresOrthographicCamera :args="[-500,500,500,-500,10,10000]" :position="cameraPos"></TresOrthographicCamera>-->
        <TresPerspectiveCamera :args="[45, 1, 0.1, 10000]" :position="cameraPos"></TresPerspectiveCamera>
        <MallMapCamera :key="level"></MallMapCamera>
        <MallMapContent @select="onSelect" :key="level" :level="level"></MallMapContent>
        <!--<TresGridHelper :position="[0, 0, 0]" scale="100" />-->
        <!--<TresAxesHelper :args="[100]"></TresAxesHelper>-->

        <!--<TresAmbientLight :intensity="0.25"/>-->

        <!--<Sphere :args="[2]" :position="positionFromEngels(45,45,100)">
            <TresMeshToonMaterial color="orange" />
        </Sphere>-->
        <TresDirectionalLight :intensity="1" cast-shadow
                              :position="positionFromEngels(45,45,100)">
        </TresDirectionalLight>

        <!--<Sphere :args="[2]" :position="positionFromEngels(180,0,100)">
            <TresMeshToonMaterial color="orange" />
        </Sphere>-->
        <TresDirectionalLight :intensity="1"
                              :position="positionFromEngels(180,0,100)">
        </TresDirectionalLight>

        <!--<Sphere :args="[2]" :position="positionFromEngels(285,45,100)">
            <TresMeshToonMaterial color="orange" />
        </Sphere>-->
        <TresDirectionalLight :intensity="1.5" cast-shadow
                              :position="positionFromEngels(285,45,100)">
        </TresDirectionalLight>

    </TresCanvas>
</template>

<script setup>
const props = defineProps({
    level: Number || String
})
const cameraPos = shallowRef([0,500,500])
const selected = ref(null)
const router = useRouter()
function onSelect(id){
    selected.value = id
    console.log('click on',id,props.level)
    router.push('/shops/'+id)
}
const key = ref(0)


function positionFromEngels(horizontal,elevation,size){

    const y = Math.sin(horizontal*Math.PI/180) * Math.cos(elevation*Math.PI/180) * size
    const x = Math.cos(horizontal*Math.PI/180) * Math.cos(elevation*Math.PI/180) * size
    const z = Math.sin(elevation*Math.PI/180) * size

    //console.log('transform ',horizontal,elevation,' to xyz',[x,z,y])
    return [x,z,y]
}


</script>

<style>
.mall-map {
    height:100%;
    background-color: transparent;
    width:100%;
    padding:0;
}
#canvas {
    height: 100%;
    width: 100%;
}
.selected-info{
    padding: 10px;
    height: 100px;
}
</style>
<template>
    <TresGroup v-if="map" ref="mapDom" v-bind="mapOptions" :key="level">
        <MallMapShop v-for="shop in map.paths"
                     :object="shop"
                     :id="shop.id"
                     :isSelected="selected===shop.id">
        </MallMapShop>
    </TresGroup>
</template>

<script setup>
const props = defineProps({
    level: Number || String
})
const emit = defineEmits(['select'])
import {Vector3, Box3, Vector2} from 'three'

const extrudeOptions = ref({
    depth: 10,
    bevelThickness: 2,
    bevelOffset:0
})
const mapOptions = ref({
    //scale:0.1,
    //rotateX: Math.PI/2,
    //rotateZ: Math.PI,
    //translateX:-50,
    //translateY:-50,
    //translateZ:-3
})
const mapDom = shallowRef()
const mapSize = shallowRef()
const route = useRoute()
const selected = computed(()=>route.params.shop)
const selectedColor = ref(null)
const boxRef = shallowRef()
const { onLoop, resume } = useRenderLoop()
const goto = ref(null)
const {camera, controls, renderer, sizes, raycaster, scene} = useTresContext()
const ticks = ref(180)
onLoop(({ delta, elapsed, clock, dt }) => {
    if(goto.value){
        camera.value.position.lerp(goto.value.position, goto.value.phase/ticks.value)
        controls.value.target.lerp(goto.value.target, goto.value.phase/ticks.value)
        if(goto.value.phase < ticks.value) goto.value.phase++
        else goto.value = null
    }
})
console.log({raycaster})
//renderer.value.domElement.addEventListener("touchstart", function(e) { console.log('canvas touch start canvas',e)},false)
//renderer.value.domElement.addEventListener("pointerdown", function(e) { console.log('canvas pointerdown',e)},false)
renderer.value.domElement.addEventListener("pointerup", function(e) {
    const normalized = [
        (e.offsetX/sizes.width.value)*2-1,
        1-(e.offsetY/sizes.height.value)*2
    ]
    console.log('canvas pointerup',e.offsetX,sizes.width.value,e.offsetY,sizes.height.value,normalized)
    raycaster.value.setFromCamera(new Vector2(...normalized), camera.value)
    const inter = raycaster.value.intersectObjects(mapDom.value.children, true)
    console.log('shop clicked',inter?.[0]?.object?.name,inter?.[0]?.distance)
    //selected.value = inter?.[0]?.object?.name
    if(inter?.[0]?.object?.name) onClick(inter?.[0]?.object?.name)
},false)


function onClick(data){
    const shop = _find(map.value.paths,{id:data})
    if(shop){
        if(!shop.defaultView){
            shop.defaultView = {
                position: getVector(camera.value.position),
                target: getVector(controls.value.target)
            }
            //console.log('save camera',shop.defaultView)
        } else {
            //console.log('get saved camera',shop.defaultView)
            goto.value = {
                phase:0,
                position: new Vector3(...shop.defaultView.position),
                target: new Vector3(...shop.defaultView.target)
            }
            //camera.value.position.set(...shop.defaultView.position)
            //camera.value.position.lerp(shop.defaultView.position,0.4)
            //controls.value.target.set(...shop.defaultView.target)
        }
    }
    //console.log('click',shop)
    //selected.value = selected.value!==data ? data : null
    emit('select',data)
    function getVector(obj){
        return [obj.x, obj.y, obj.z]
    }
}
function onPointerEnter(ev) {
    console.log(ev);
    if (ev) {
        //ev.object.material.color.set('#DFFF45');
    }
}
console.log('Mall map content started')
import { SVGLoader } from 'three-stdlib'
const map = shallowRef()
const loader = new SVGLoader()
const store = useStore()
const tenants = computed(()=>store.tenants)
loader.load('/maps/zt-0-0.svg',(data)=>{
    console.log('raw svg',data)
    let id = 1
    map.value = {
        paths:_map(data.paths,p=>{
            return {
                color:p.color,
                shapes:p.toShapes(),
                id: tenants.value[id++].id
            }
        }),
    }
    nextTick(()=>{
        console.log('svg loaded',mapDom.value)
        const box = new Box3().setFromObject(mapDom.value)
        const boxSize = box.getSize(new Vector3())
        mapSize.value = boxSize
        console.log('mall size',boxSize)
        mapDom.value.rotateX(Math.PI/2)
        mapDom.value.translateZ(-boxSize.z)
        mapDom.value.translateX(-boxSize.x/2)
        mapDom.value.translateY(-boxSize.y/2)
    })

})
function onGroupClick(e){
    console.log('on group clikc',e)
}
/*
async function loadMapFromWeb (url, options={}){

    return useFetch( url, {...options, transform:(data)=>{
            console.log('retried data',data)
            return data
        }})
}
const mapUrl = 'https://zlotetarasy.pl/api/shops'

const {data, error} = await loadMapFromWeb(mapUrl)
console.log({data, error})
*/
</script>

<style scoped>

</style>
<template>
    <OrbitControls v-bind="controlsState"></OrbitControls>
    <!--<CameraControls :camera="camera" make-default v-bind="controlsState"></CameraControls>-->
    <!--<MapControls v-bind="controlsState"></MapControls>-->
</template>

<script setup>
    const {camera, controls, renderer, sizes} = useTresContext()
    //console.log({camera, controls, renderer, sizes})
    //console.log('pixelratio',renderer.value.getPixelRatio())

    const controlsState = reactive({
        minDistance: 300,
        maxDistance: 1500,
        enableDamping: true,
        makeDefault: true,
        camera,
        maxPolarAngle: Math.PI/3,
        minPolarAngle: Math.PI/10,
        minAzimuthAngle: -Math.PI,
        maxAzimuthAngle: Math.PI,
        autoRotate: false
    })
    const { onLoop, resume } = useRenderLoop()
    onLoop(({ delta, elapsed, clock, dt }) => {
        //camera.value.update()
        controls.value.update()
    })
</script>

<style scoped>

</style>
<template>
    <TresMesh ref="shopRef"
              :scale-z="isSelected?1.2:1"
              :name="id"
              :position-z="isSelected?-5:0" cast-shadow receive-shadow>
        <TresExtrudeGeometry v-for="shape in object.shapes" :args="[shape,extrudeOptions]">
            <Sparkles></Sparkles>
        </TresExtrudeGeometry>
        <TresMeshPhongMaterial :color="isSelected?'#ffe834':object.color" :shininess="100">
        </TresMeshPhongMaterial>
    </TresMesh>
</template>

<script setup>

const props = defineProps({
    object: Object,
    id: String,
    isSelected: Boolean
})
const emit = defineEmits(['click'])
const extrudeOptions = ref({
    depth: 13,
    bevelThickness: 3,
    bevelOffset:-1
})
const shopRef = shallowRef()
const {camera, controls, renderer, sizes} = useTresContext()


//console.log('adding shop to map',props.id)




</script>

<style scoped>

</style>